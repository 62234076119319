import { Icon } from 'components/Phantom/Icon'
import styles from './QuizModal.module.scss'
import { amOpenSleepQuizModal } from 'events/amplitude'
import { Button } from 'components/WebEv/Buttons'
import { useStore } from 'stores/StoreProvider'

export const QuizTrigger: React.FC = () => {
	const toggleSleepQuizModal = useStore((state) => state.modal.toggleSleepQuizModal)

	const handleShowQuiz = () => {
		toggleSleepQuizModal()

		// use session storage to prevent ecap modal from showing on a timer or scroll.
		sessionStorage.setItem('hide_ecap', 'true')

		amOpenSleepQuizModal()
	}

	return (
		<Button.Empty
			id="sleep-quiz-trigger"
			onClick={handleShowQuiz}
			className={styles.quiz_trigger_button}
		>
			Discover your perfect sleep set up. Take quiz <Icon name={'ArrowRightLight'} />
		</Button.Empty>
	)
}

export default QuizTrigger
