import cx from 'classnames'
import { A } from 'components/basic/A'
import styles from 'components/NavBar/NavBar.module.scss'
import { usePathname } from 'next/navigation'
import { FC, RefObject, useEffect, useState } from 'react'
import { RegionShort } from 'utils/internationalization'

export interface NavBarItemProps {
	title: string
	highlight?: boolean
	href: string
	onClick?: () => void
	setRef?: (ref: RefObject<HTMLDivElement>) => void
	excludeRegion?: RegionShort[]
	tracking?: () => void
}

export const NavBarItem: FC<NavBarItemProps> = (props) => {
	const pathname = usePathname()
	const [highlight, setHighlight] = useState(false)

	const { setRef, href } = props
	useEffect(() => {
		if (pathname === href || pathname === href + '/') {
			setHighlight(true)
		} else {
			setHighlight(false)
		}
	}, [setHighlight, setRef, href, pathname])

	const handleClick = () => {
		if (props.tracking) {
			props.tracking()
		}

		if (props.onClick !== undefined) {
			props.onClick()
		}
	}

	const id = `nav-bar-link-to-${props.title?.toLowerCase().replace(' ', '-')}`

	return (
		<A
			className={cx(styles.nav_bar_item, { [styles.nav_bar_item_highlight]: highlight })}
			onClick={handleClick}
			href={props.href}
			id={id}
		>
			{props.title}
		</A>
	)
}
