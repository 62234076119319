import { FC, Fragment, MouseEventHandler, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import Slider, { Settings } from 'react-slick'
import merge from 'lodash.merge'
import styles from './SlickSlider.module.scss'
import { getKeyboardFocusableElements } from 'utils/tools'
import { amClickedButton } from 'events/amplitude'

export interface SlideItem {
	component: ReactNode
	src?: string
	alt?: string
}

interface Props {
	items: SlideItem[]
	showThumbs?: boolean
	settings?: Settings
	className?: string
	id?: string
}

// full docs at https://react-slick.neostack.com/docs/api
const defaultSettings: Settings = {
	accessibility: true,
	adaptiveHeight: false,
	arrows: true,
	autoplay: false,
	autoplaySpeed: 0,
	centerMode: false,
	centerPadding: '0px',
	draggable: false,
	dots: false,
	easing: 'linear',
	focusOnSelect: false,
	infinite: true,
	initialSlide: 0,
	lazyLoad: undefined, //"ondemand" | "progressive" | "anticipated";
	slidesToScroll: 1,
	swipeToSlide: true,
	slidesToShow: 1,
	speed: 100,
	variableWidth: true,
}

const disableFocusOnChildren = (elements: Element, savePreviousTabIndex: boolean) => {
	getKeyboardFocusableElements(elements).forEach((element) => {
		if (savePreviousTabIndex) {
			element.setAttribute('data-tabindex', element.getAttribute('tabindex') || '0')
		}
		element.setAttribute('tabindex', '-1')
	})
}

const handleSliderFocusability = (rootElement: HTMLDivElement, currentSlideIndex: number, isSlideChange: boolean) => {
	if (rootElement) {
		disableFocusOnChildren(rootElement, !isSlideChange)
		if (currentSlideIndex !== -1) {
			const currentSlideDiv = rootElement.querySelector(`.slick-slide[data-index="${currentSlideIndex}"]`) as HTMLDivElement
			if (currentSlideDiv) {
				const focusableElementsInCurrentSlide = getKeyboardFocusableElements(currentSlideDiv)
				focusableElementsInCurrentSlide.forEach((element) => {
					element.setAttribute('tabindex', element.getAttribute('data-tabindex') || '0')
				})
				if (isSlideChange && focusableElementsInCurrentSlide.length > 0) {
					const cfe = focusableElementsInCurrentSlide[0] as HTMLElement
					cfe.focus({ preventScroll: true })
				}
			}
		}
	}
}

export const SlickSlider: FC<Props> = (props) => {
	const settings = merge({}, defaultSettings, props.settings) as Settings
	const sliderItems = props.items

	const rootDivRef = useRef<HTMLDivElement>(null)
	const sliderRef = useRef<Slider>(null)
	const keyboardArrowsRef = useRef(false)
	const [currentSlide, setcurrentSlide] = useState(settings.initialSlide)
	const [isMounted, setIsMounted] = useState(false)

	useEffect(() => setIsMounted(true), [])

	if (props.showThumbs) {
		settings.dots = true
		settings.dotsClass = 'slick-dots slick-thumb'
		settings.customPaging = (i: number) => {
			const src = sliderItems[i]?.src
			return src ? (
				<button
					key={`slider-shop-thumb-${src}`}
					onClick={() => {
						amClickedButton('Clicked Shop Page Thumbnail', src)
					}}
				>
					{src.lastIndexOf('.mp4') > -1 ? (
						<video
							muted={true}
							loop={true}
							playsInline={true}
							autoPlay={true}
							src={src}
						></video>
					) : (
						<img
							src={src}
							alt={sliderItems[i].alt}
						/>
					)}
				</button>
			) : (
				<></>
			)
		}
	}

	const PrevArrow = (props: { onClick?: MouseEventHandler<HTMLButtonElement> }) => (
		<button
			id="shop-page-prev-arrow"
			className={cx(styles.arrow_button, styles.prev_arrow, 'prev_arrow', !settings.infinite && currentSlide === 0 && 'hidden')}
			type="button"
			aria-label="Previous slide"
			onClick={(e) => {
				amClickedButton('Previous Arrow', 'shop-page-prev-arrow')
				props.onClick && props.onClick(e)
			}}
		/>
	)
	const NextArrow = (props: { onClick?: MouseEventHandler<HTMLButtonElement> }) => (
		<button
			id="shop-page-next-arrow"
			className={cx(styles.arrow_button, styles.next_arrow, 'next_arrow', !settings.infinite && currentSlide === sliderItems.length - 1 && 'hidden')}
			type="button"
			aria-label="Next slide"
			onClick={(e) => {
				amClickedButton('Next Arrow', 'shop-page-next-arrow')
				props.onClick && props.onClick(e)
			}}
		/>
	)

	settings.prevArrow = <PrevArrow />
	settings.nextArrow = <NextArrow />

	useEffect(() => {
		setTimeout(() => {
			if (!settings.autoplay) {
				handleSliderFocusability(rootDivRef.current?.querySelector('.slick-list') as HTMLDivElement, settings.slidesToShow === 1 || settings.centerMode ? settings.initialSlide ?? 0 : -1, false)
			}
		}, 1000)
	}, [settings.slidesToShow, settings.centerMode, settings.initialSlide, settings.autoplay])

	const onSlideChange = useCallback(
		(i: number) => {
			setcurrentSlide(i)
			if (!settings.autoplay && (settings.slidesToShow === 1 || settings.centerMode)) {
				setTimeout(() => {
					handleSliderFocusability(rootDivRef.current?.querySelector('.slick-list') as HTMLDivElement, i, true)
					keyboardArrowsRef.current = false
				}, 200)
			}
			settings.afterChange?.(i)
		},
		[keyboardArrowsRef, settings]
	)

	// Listen for arrow left|right keypress, which normally inside the slider is used for navigating between slides
	// in case such navigation via arrow keys is triggered, use the onSlideChange event handler above to try and force a focus change inside the slider
	// focus into the currently active slide, if there's anything to focus into (see handleSliderFocusability function)
	const onKeyUp = (event: React.KeyboardEvent) => {
		keyboardArrowsRef.current = event.key === 'ArrowLeft' || event.key === 'ArrowRight'
	}

	return (
		<div
			ref={rootDivRef}
			onKeyUp={onKeyUp}
			tabIndex={0}
			role="toolbar"
			aria-label="Image slider"
		>
			<Slider
				ref={sliderRef}
				{...settings}
				afterChange={onSlideChange}
				className={cx(styles.container, props.showThumbs && styles.show_thumbs, settings.dots && styles.show_dots, { [styles.loaded]: isMounted }, props.className)}
			>
				{sliderItems.map((item, i) => (
					<Fragment key={i}>{item.component}</Fragment>
				))}
			</Slider>
		</div>
	)
}
