import React, { FC, useState } from 'react'
import cx from 'classnames'
import styles from './CountryPickerMobile.module.scss'
import { Img } from 'components/basic/Img'
import { CountryPickerProps } from 'utils/internationalization'
import { Button } from 'components/basic/Button'
import { getCountries, getCountry } from 'components/NavBar/CountryPicker/flags'
import { usePathname } from 'next/navigation'

export const CountryPickerMobile: FC<CountryPickerProps> = (props) => {
	const [open, setOpen] = useState(false)

	function toggleDrawer() {
		props.onClick ? props.onClick() : setOpen(!open)
	}

	const currentCountry = getCountry(props.region)
	const countries = getCountries(props.region)

	const pathname = usePathname()
	if (pathname.startsWith('/blog')) {
		return null
	}

	const regionsRendered = countries.map((country, i) => {
		return (
			<Button
				unstyled
				onClick={() => {
					props.setRegion(country.region)
					setOpen(false)
				}}
				key={`${i} mobile flag`}
				id={`${country.region}-mobile-flag`}
				content={
					<span className={styles.country_button}>
						<svg viewBox="0 0 21 15">{country.flag}</svg>
						<p>
							{country.name} <span>({country.currencyTitle})</span>
						</p>
					</span>
				}
			/>
		)
	})

	return (
		<div className={cx(styles.country_picker_mobile)}>
			<div className={cx(styles.top_line)}>
				<Button
					unstyled
					className={cx(styles.dropdown_button)}
					onClick={toggleDrawer}
					id={'mobile-country-picker-top'}
					content={
						<>
							<span className={styles.country_button}>
								<svg viewBox="0 0 21 15">{currentCountry.flag}</svg>
								<p>
									{currentCountry.name} <span>({currentCountry.currencyTitle})</span>
								</p>
							</span>

							<Img
								src={'https://eightsleep.imgix.net/expand_arrow_0a90cfd3-0caf-4227-80e9-c3c7a7a22696.svg?v=1650559768'}
								alt={'dropdown arrow'}
								className={cx(styles.dropdown_arrow, { [styles.dropdown_arrow_open]: open })}
							/>
						</>
					}
				/>
			</div>

			<div className={cx(styles.drawer, { [styles.drawer_open]: open })}>{regionsRendered}</div>
		</div>
	)
}
