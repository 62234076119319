'use client'

import { FC, useEffect, useState } from 'react'
import { LoadingSkeletonProps } from './types'
import styles from './LoadingSkeleton.module.scss'
import cx from 'classnames'

/**
 * This component is meant to be used as a visually appealing placeholder for
 * content that must be loaded client-side. It adheres to the constraints of
 * proper hydration, and will load the same thing on the server as well as the
 * initial client-side render.
 *
 * Only once the component has mounted will the children be rendered.
 */
export const LoadingSkeleton: FC<LoadingSkeletonProps> = (props) => {
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		setMounted(true)
	}, [])

	if (isLoaded(mounted, props.forceLoading)) {
		return <>{props.children}</>
	}

	const { animation = 'pulse', width, height, shape = 'rounded', fontSize, className, light = false } = props

	const getColor = () => {
		if (props.color) return props.color
		if (light) return 'rgba(255, 255, 255, 0.3)'
		return 'rgba(0, 0, 0, 0.15)'
	}

	return (
		<div
			className={cx(styles.skeleton, getAnimationClass(animation), className)}
			style={{
				width,
				height,
				fontSize,
				backgroundColor: getColor(),
				borderRadius: getBorderRadius(shape),
			}}
		/>
	)
}

const getBorderRadius = (shape: LoadingSkeletonProps['shape']): string => {
	switch (shape) {
		case 'circle':
			return '50%'
		case 'rounded':
			return '0.25rem'
		default:
			return '0'
	}
}

const getAnimationClass = (animation: LoadingSkeletonProps['animation']): string => {
	switch (animation) {
		case 'pulse':
			return styles.pulse
		case 'wave':
			return styles.wave
		default:
			return ''
	}
}

const isLoaded = (mounted: boolean, forceState?: LoadingSkeletonProps['forceLoading']): boolean => {
	if (forceState !== undefined) {
		return forceState === false
	}

	return mounted
}
