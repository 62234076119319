import { useOnClickOutside } from 'components/_hooks/useOnClickOutside'
import { amClickedButton } from 'events/amplitude'
import { usePathname } from 'next/navigation'
import { FC, useId, useRef, useState } from 'react'
import { RegionShort } from 'utils/internationalization'
import styles from './CountryPicker.module.scss'
import { getCountries, getCountry } from './flags'

export interface CountryPickerProps {
	region: RegionShort
	clickCountry: (region: RegionShort) => void
	onClick?: () => void

	flip?: boolean
	flipHorizontal?: boolean
}

export const CountryPicker: FC<CountryPickerProps> = (props) => {
	const [open, setOpen] = useState(false)
	const container = useRef<HTMLDivElement | null>(null)
	const id = useId()

	const currentCountry = getCountry(props.region)
	const countries = getCountries(props.region)

	useOnClickOutside(container, () => setOpen(false))

	const pathname = usePathname()
	if (pathname.startsWith('/blog')) {
		return null
	}

	return (
		<div
			className={styles.container}
			ref={container}
			role="button"
			aria-expanded={open}
			aria-label="Toggle country picker"
			tabIndex={0}
			onClick={() => {
				amClickedButton('', 'desktop-country-picker-top')
				props.onClick
					? props.onClick()
					: setOpen((prev) => {
							return !prev
					  })
			}}
			onKeyDown={(e) => {
				if (e.key === 'Enter' || e.key === ' ') {
					e.preventDefault()
					amClickedButton('', 'desktop-country-picker-top')
					props.onClick
						? props.onClick()
						: setOpen((prev) => {
								return !prev
						  })
				}
			}}
		>
			<div className={styles.button}>
				<svg viewBox="0 0 21 15">{currentCountry.flag}</svg>

				<span style={{ transform: open ? '' : 'rotate(180deg)' }}>
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M3.40751 10.1757L3.8248 10.5927C3.92357 10.6914 4.08371 10.6914 4.18249 10.5927L8.00008 6.78669L11.8177 10.5927C11.9165 10.6914 12.0766 10.6914 12.1754 10.5927L12.5927 10.1757C12.6914 10.077 12.6914 9.91693 12.5927 9.81821L8.17891 5.4074C8.08015 5.3087 7.92001 5.3087 7.82122 5.4074L3.40748 9.81821C3.30872 9.91693 3.30872 10.077 3.40751 10.1757Z"
							fill="white"
						/>
					</svg>
				</span>
			</div>

			<div
				data-flip={props.flip}
				data-flip-horizontal={props.flipHorizontal}
				className={styles.dropdown}
				style={{ opacity: open ? 1 : 0, visibility: open ? 'visible' : 'hidden' }}
			>
				<ul className={styles.countries_list}>
					{countries.map((country, i) => (
						<li key={`pick-key-${i}`}>
							<button
								className={styles.country}
								onClick={() => {
									props.clickCountry(country.region)
									amClickedButton('[children]', `${country.region}-desktop-flag`)
								}}
							>
								<svg viewBox="0 0 21 15">{country.flag}</svg>
								<p>
									{country.name} <span>({country.currencyTitle})</span>
								</p>
							</button>
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}
