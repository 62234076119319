'use client'

import { ClientOnly } from 'components/ClientOnly'
import { EmailInput } from 'components/EmailInput/EmailInput'
import { eight, Icon } from 'components/LegacyIcon'
import { CountryPicker } from 'components/NavBar/CountryPicker'
import { trackClickSupport } from 'events/index'
import { useParams, usePathname, useSearchParams } from 'next/navigation'
import { PriceManager } from 'prices'
import { FC, useEffect, useState } from 'react'
import { PaymentOption } from 'shop/Shop.types'
import { useStore } from 'stores/StoreProvider'
import { useGetUpToDiscountNumber } from 'stores/prices'
import { useCurrentRegion } from 'stores/settings'
import { CaptureLocation } from 'utils/events'
import { getSafeLocale, redirectToRegion, RegionShort } from 'utils/internationalization'
import styles from './Footer.module.scss'
import { FooterListItem, getSocialLinks } from './subcomponents'
import { getBlogItems, getCompanyItems, getLegalItems, getPhoneNumber, getSupportItems } from './utils'

export interface FooterProps {
	region: RegionShort
	baseDiscountAmount: number

	hideEmail?: boolean
	paymentOption?: PaymentOption
}

/**
 * @deprecated Use /components/Footer instead
 */
export const FooterConnected = () => {
	const currentRegion = useCurrentRegion()
	const baseDiscountAmount = useGetUpToDiscountNumber()
	const paymentOption = useStore((state) => state.shop.paymentOption)

	return (
		<Footer
			region={currentRegion}
			baseDiscountAmount={baseDiscountAmount}
			paymentOption={paymentOption}
		/>
	)
}

/**
 * @deprecated Use /components/Footer instead

 */
export const Footer: FC<FooterProps> = (props) => {
	const [isLoggedIn, setIsLoggedIn] = useState(false)
	const toggleLocalizeModal = useStore((state) => state.modal.toggleLocalizeModal)

	useEffect(() => {
		const isClientSide = typeof window !== 'undefined'
		const isLoggedIn = isClientSide && (localStorage.getItem('tokenData') || localStorage.getItem('authToken'))
		setIsLoggedIn(!!isLoggedIn)
	}, [])

	const pathname = usePathname()
	const params = useParams()
	const searchParams = useSearchParams()

	const locale = getSafeLocale(params)
	const utmSource = searchParams.get('utm_source')
	const phoneNumber = getPhoneNumber(props.region)

	const handleLocalize = props.region === 'eu' && typeof window !== 'undefined'
	const handleClick = handleLocalize ? toggleLocalizeModal : undefined

	const handleSetRegion = (region: RegionShort) => {
		redirectToRegion(region, locale, pathname)
	}

	const amount = props.baseDiscountAmount
	const discount = PriceManager.formatPriceToCurrencyNoDecimal(amount, PriceManager.getRegionCurrency(props.region))

	const hideEmail = utmSource === 'app' || props.hideEmail
	const specialSleepElixirPage = pathname.includes('sleep-elixir-waitlist')
	const est = new Date(new Date().toLocaleString('en', { timeZone: 'America/New_York' }))
	// TODO this should be moved out of the component and into a prop

	const showContactInfo = est.getHours() > 9 && est.getHours() < 17

	return (
		<footer
			className={styles.footer}
			id={'footer'}
		>
			<a
				className="skip-main"
				href="#header"
				aria-label="Anchor link to Header"
			>
				Header
			</a>
			<a
				className="skip-main"
				href="#main"
				aria-label="Anchor link to Main Content"
			>
				Main Content
			</a>

			<div>
				<div className={styles.logo}>
					<Icon icon={eight} />
				</div>
				<h2 className={'vh'}>Eight Sleep</h2>
			</div>

			<div className={styles.country_picker_container}>
				<CountryPicker
					region={props.region}
					clickCountry={handleSetRegion}
					onClick={handleClick}
					flipHorizontal
				/>
			</div>

			<div className={styles.inner}>
				<ClientOnly>
					{/* <div
						className={styles.email_container}
						style={{ display: specialSleepElixirPage ? 'none' : '' }}
					>
						{hideEmail ? (
							<></>
						) : (
							<>
								<h3 className={styles.email_header}>
									Get up to{' '}
									<LoadingSkeleton
										light
										height={'0.8em'}
										width={'6ch'}
									>
										{discount}
									</LoadingSkeleton>{' '}
									off
									<br />
									<span>Because extraordinary sleep makes a difference</span>
								</h3>
								<div className={styles.input_container}>
									<EmailInput
										location={CaptureLocation.FOOTER}
										placeholder={'Email address'}
										errorMessage={'Email is invalid, please try again.'}
									/>
									<p className={styles.disclaimer}>By signing up here I agree to receive Eight Sleep’s email newsletter and special promotional offers.</p>
								</div>
								<p className={styles.asterisk}>* Individual results may vary.</p>
							</>
						)}
					</div> */}
					<div
						className={styles.email_container}
						style={{ display: specialSleepElixirPage ? 'none' : '' }}
					>
						{hideEmail ? (
							<></>
						) : (
							<>
								<h3 className={styles.email_header}>
									Join the Sleep Revolution
									<br />
									<span>Sign up to stay updated on the newest in sleep technology</span>
								</h3>
								<div className={styles.input_container}>
									<EmailInput
										location={CaptureLocation.FOOTER}
										placeholder={'Email address'}
										errorMessage={'Email is invalid, please try again.'}
									/>
									<p className={styles.disclaimer}>By signing up here I agree to receive Eight Sleep’s email newsletter and special promotional offers.</p>
								</div>
								<p className={styles.asterisk}>* Individual results may vary.{props.paymentOption === 'fragile' && <span>** Requires active rental agreement.</span>}</p>
							</>
						)}
					</div>
				</ClientOnly>
				<ul className={styles.footer_list}>
					<FooterListItem
						header={'Blog'}
						sublistItems={getBlogItems()}
					/>
					<FooterListItem
						header={'Company'}
						sublistItems={getCompanyItems()}
					/>
					<FooterListItem
						header={'Support'}
						sublistItems={getSupportItems(isLoggedIn)}
					/>
					<FooterListItem
						header={'Legal'}
						sublistItems={getLegalItems(props.region)}
					/>
				</ul>
			</div>

			<div className={styles.contact_container}>
				<div
					className={styles.call_us}
					style={{ visibility: showContactInfo ? 'visible' : 'hidden' }}
				>
					<h3>Contact Us</h3>
					<div className={styles.contact_links}>
						{/*
					<a
						href={'mailto: support@eightsleep.com'}
						onClick={() => trackClickSupport('Email')}
					>
						support@eightsleep.com
					</a>
					*/}
						<a
							href={phoneNumber.link}
							onClick={() => trackClickSupport('Phone Number')}
						>
							{phoneNumber.string}
						</a>
					</div>
				</div>
				<div className={styles.social_links}>
					<h3 className={'vh'}>Social Links</h3>
					<ul>{getSocialLinks()}</ul>
				</div>
			</div>

			<div className={styles.copyright_and_country}>
				<p className={styles.copyright}>&copy; {new Date().getFullYear()} Eight Sleep. All rights reserved.</p>
			</div>

			{hideEmail && <p className={styles.asterisk}>* Individual results may vary.{props.paymentOption === 'fragile' && <span>** Requires active rental agreement.</span>}</p>}
		</footer>
	)
}
